import React, { Component, useState, useEffect } from "react";
import { ReactDOM } from "react";
import Dpad from "../components/Dpad";
import PadButton from "../components/PadButton";

export default function Pad(props) {
  const { msgFunc /*padHtml*/ } = props;

  const padbutton = (id) => {
    return { type: "padbutton", buttonId: id };
  };
  const buttonId = (id) => {
    return `button${id}`;
  };
  const menus = [padbutton("a"), padbutton("b")];
  const menuList = menus.map((menu) => {
    if (menu.type === "padbutton") {
      return (
        <PadButton
          msgFunc={msgFunc}
          key={Math.random()}
          childClass="padButton"
          //childId={buttonId(menu.buttonId)}
          buttonId={menu.buttonId}
        />
      );
    }
  });

  return (
    <div id="pad" className="pad hidden">
      <div className="portrait">
        <button className="fullscreenButton" onClick={() => showFullScreen()}>
          Turn on Pad
        </button>

        <Dpad msgFunc={msgFunc} />
        <div id="keybuttons">{menuList}</div>
      </div>
    </div>
  );

  function toggleFullScreen() {
    // if (window.ReactNativeWebView) {
    //   setTimeout(function () {
    //     window.ReactNativeWebView.postMessage("padFullscreen");
    //   }, 2000);
    // } else {
    const element = document.getElementById("pad");
    if (!document.fullscreenElement) {
      if (element.requestFullscreen) return element.requestFullscreen();
      if (element.webkitRequestFullscreen)
        return element.webkitRequestFullscreen();
      if (element.mozRequestFullScreen) return element.mozRequestFullScreen();
      if (element.msRequestFullscreen) return element.msRequestFullscreen();
    } else {
      if (document.exitFullscreen) return document.exitFullscreen();
      if (document.webkitCancelFullscreen)
        return document.webkitCancelFullscreen();
      if (document.mozCancelFullScreen) return document.mozCancelFullScreen();
      if (document.msExitFullscreen) return document.msExitFullscreen();
    }
    // }
  }
  function showFullScreen() {
    document.getElementById("pad").classList.toggle("hidden");
  }
}
