import React, { useState, useEffect } from "react";
import PadButton from "../components/PadButton";

export default function Dpad(props) {
  const { msgFunc } = props;
  return (
    <section id="d-pad">
      <PadButton
        msgFunc={msgFunc}
        key={Math.random()}
        childClass="padButton up"
        buttonId="up"
      />
      <PadButton
        msgFunc={msgFunc}
        key={Math.random()}
        childClass="padButton down"
        buttonId="down"
      />
      <PadButton
        msgFunc={msgFunc}
        key={Math.random()}
        childClass="padButton right"
        buttonId="right"
      />
      <PadButton
        msgFunc={msgFunc}
        key={Math.random()}
        childClass="padButton left"
        buttonId="left"
      />
    </section>
  );
}
