import React, { Component, useEffect, useState } from "react";

import Join from "./pages/Join";
import Title from "./pages/Title";
import "./app.css";
import { Routes, Route, useLocation } from "react-router-dom";
import qs from "qs";

export default function App() {
  useEffect(() => {
    setRealHeight();
    preventZoom();
  }, []);

  const { search } = useLocation();

  return (
    <main className="App-header module" id="SplashScreen">
      <div className="Container" id="white">
        <Routes>
          <Route path="/" element={<Title />} />
          <Route path="/join/" element={<Join query={getQuery(search)} />} />
        </Routes>
      </div>
    </main>
  );
}

function setRealHeight() {
  let vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty("--vh", `${vh}px`);
  window.addEventListener("resize", () => {
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty("--vh", `${vh}px`);
  });
  window.addEventListener("touchend", () => {
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty("--vh", `${vh}px`);
  });
}

function preventZoom() {
  document.documentElement.addEventListener(
    "touchstart",
    function (event) {
      if (event.touches.length > 1) {
        event.preventDefault();
      }
    },
    false
  );

  var lastTouchEnd = 0;

  document.documentElement.addEventListener(
    "touchend",
    function (event) {
      var now = new Date().getTime();
      if (now - lastTouchEnd <= 300) {
        event.preventDefault();
      }
      lastTouchEnd = now;
    },
    false
  );

  document.documentElement.addEventListener("dragstart", (event) =>
    event.preventDefault()
  );
  document.documentElement.addEventListener("contextmenu", (event) =>
    event.preventDefault()
  );
  document.documentElement.addEventListener("selectstart", (event) =>
    event.preventDefault()
  );
}

function getQuery(search) {
  const query = qs.parse(search, {
    ignoreQueryPrefix: true,
  });

  return { room: query.room, nickname: query.nickname };
}
