import React, { Component, useState, useEffect } from "react";
import Pad from "./Pad";
import io from "socket.io-client";

let socket;
const url = "https://io.partypad.xyz";
let myPeerConnection;
let myDataChannel;

export default function Join({ query }) {
  const [nickname, setNickname] = useState(query.nickname);
  const [roomId, setRoomId] = useState(query.room);
  const [PadHtml, setPadHtml] = useState({ __html: "" });
  const [isConnecting, setConnecting] = useState(false);

  useEffect(() => {
    if (roomId === "" || roomId === undefined) {
      return;
    }
    console.log(roomId);
    getConnect();
  }, [roomId]);

  return (
    <div>
      <h2>
        {nickname}! Connecting to {roomId}...
      </h2>
      {isConnecting && <button onClick={showFullScreen}>Turn on Pad</button>}
      {isConnecting && <Pad msgFunc={sendButtonMsg} PadHtml={PadHtml} />}
    </div>
  );

  function getConnect() {
    try {
      socket = io(url);
      socket.on("connect", () => {
        //재접속시??
        socket.emit("pad");
        socket.once("pad", () => {
          socket.emit("nickname", nickname);
          socket.emit("join_game", roomId);
          socket.on("offer", async (offer) => {
            myPeerConnection.addEventListener("datachannel", (event) => {
              myDataChannel = event.channel;
              myDataChannel.send("Hello from Web");
              myDataChannel.addEventListener("message", ({ data }) =>
                console.log(data)
              );
            });
            const op = JSON.parse(offer);
            myPeerConnection.setRemoteDescription(op);
            const answer = await myPeerConnection.createAnswer();
            myPeerConnection.setLocalDescription(answer);
            socket.emit("answer", answer);
            setConnecting(true);

            socket.on("ice", (ice) => {
              console.log("received candidate");
              const iceInit = JSON.parse(ice);
              console.log(iceInit);
              myPeerConnection.addIceCandidate(iceInit);
            });
          });
        });
      });
      myPeerConnection = new RTCPeerConnection({
        iceServers: [
          {
            urls: [
              "stun:stun.l.google.com:19302",
              "stun:stun1.l.google.com:19302",
              "stun:stun2.l.google.com:19302",
              "stun:stun3.l.google.com:19302",
              "stun:stun4.l.google.com:19302",
            ],
          },
        ],
      });
      myPeerConnection.addEventListener("icecandidate", handleIce);
    } catch (e) {
      console.log(e);
    }
  }
  function sendMessage(type, payload) {
    const msg = JSON.stringify({ type: type, payload: payload });
    myDataChannel.send(msg);
    console.log("sent msg", msg);
  }

  function sendButtonMsg(type, payload) {
    sendMessage(type, payload);
  }

  function showFullScreen() {
    document.getElementById("pad").classList.toggle("hidden");
  }

  function handleIce(data) {
    if (data.candidate == null) return;
    console.log("sent candidate : ", data.candidate);
    const { candidate, sdpMid, sdpMLineIndex } = data.candidate;
    const iceInit = {
      candidate: candidate,
      sdpMid: sdpMid,
      sdpMLineIndexdpM: sdpMLineIndex,
    };
    socket.emit("ice", iceInit);
  }
}
