import React, { Component, useState } from "react";
import { useNavigate } from "react-router-dom";

export default function Title(props) {
  const navigate = useNavigate();
  return (
    <div id="TitleScene">
      <p />
      <button onClick={(event) => event.preventDefault()}>
        Find Games on Local Network
      </button>
      <p></p>
      <div>
        <form id="loginForm">
          <input
            required
            type="text"
            name="roomId"
            placeholder="roomId"
            id="input_roomId"
          ></input>
          <input
            required
            type="text"
            name="nickname"
            placeholder="Nickname"
            id="input_nickname"
          ></input>
          <button type="button" onClick={() => HandleJoinButton()}>
            Join Game
          </button>
        </form>
      </div>
      <p></p>
    </div>
  );

  function HandleJoinButton(event) {
    const input_nickname = document.getElementById("input_nickname").value;
    const input_roomId = document.getElementById("input_roomId").value;
    navigate(`/join/?room=${input_roomId}&nickname=${input_nickname}`);
  }
}
