import React, { useState, useEffect } from "react";

export default function PadButton(props) {
  const { msgFunc, childClass, buttonId } = props;
  return (
    <button
      className={childClass}
      onMouseDown={(event) => {
        event.preventDefault();

        handleClickButton(event, true);
        buttonFunc(buttonId, true);
      }}
      onTouchStart={(event) => {
        event.preventDefault();

        handleClickButton(event, true);
        buttonFunc(buttonId, true);
      }}
      onMouseUp={(event) => {
        event.preventDefault();

        handleClickButton(event, false);
        buttonFunc(buttonId, false);
      }}
      onTouchEnd={(event) => {
        event.preventDefault();

        handleClickButton(event, false);
        buttonFunc(buttonId, false);
      }}
    >
      {buttonId}
    </button>
  );

  function buttonFunc(buttonId, payload) {
    msgFunc("keyInput", JSON.stringify({ type: buttonId, payload: payload }));
  }

  function handleClickButton(event, isActive) {
    //event.preventDefault();
    if (isActive) {
      event.target.style.background = "linear-gradient(yellow, orange)";
      event.target.style.borderImage = "rgba(0, 0, 0, 0)";
      event.target.style.color = "black";
    } else {
      event.target.style.background = "rgba(0, 0, 0, 0)";
      event.target.style.border = "solid";
      event.target.style.borderImage = "linear-gradient(yellow, orange)";
      event.target.style.borderImageWidth = "1.5vh";
      event.target.style.borderImageSlice = 1;
      event.target.style.color = "yellow";
    }
  }
}
